var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "addresslist" },
      [
        _c("headbox", {
          attrs: {
            title: "新增地址",
            bgColor: "#fff",
            isborder: "true",
            isbackpickup: true,
            path: _vm.path
          }
        }),
        _vm.ishow
          ? [
              _c("van-empty", {
                staticClass: "custom-image",
                attrs: {
                  image: require("@/assets/images/pic_no information@2x.png"),
                  description: "暂无数据!"
                }
              })
            ]
          : _vm._e(),
        !_vm.ishow
          ? _c(
              "div",
              { staticClass: "padd" },
              _vm._l(_vm.addresslist, function(items) {
                return _c("div", { key: items.id, staticClass: "whitebox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "addressbox",
                      on: {
                        click: function($event) {
                          return _vm.backpickup(items)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex infobox" }, [
                        _c("div", [_vm._v(_vm._s(items.name))]),
                        _c("div", { staticClass: "marginLeft" }, [
                          _vm._v(_vm._s(items.phone))
                        ])
                      ]),
                      _c("div", { staticClass: "adressdetails" }, [
                        _vm._v(
                          " " +
                            _vm._s(items.preAddr) +
                            _vm._s(items.address) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "flex-between bottombox" }, [
                    _c("div"),
                    items.defAddr
                      ? _c(
                          "div",
                          { staticClass: "abscheckbox" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: items.defAddr,
                                callback: function($$v) {
                                  _vm.$set(items, "defAddr", $$v)
                                },
                                expression: "items.defAddr"
                              }
                            }),
                            _c("span", [_vm._v("默认寄件地址")])
                          ],
                          1
                        )
                      : _c("div", { staticClass: "abscheckbox" }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.initdata(
                                    items.id,
                                    items.name,
                                    items.phone,
                                    items.address,
                                    items.preAddr,
                                    items.code
                                  )
                                }
                              }
                            },
                            [
                              _c("el-checkbox"),
                              _vm._v(" "),
                              _c("span", [_vm._v(" 设置为默认寄件地址")])
                            ],
                            1
                          )
                        ]),
                    _c("div", { staticClass: "flex bottomboxbtn" }, [
                      _c(
                        "div",
                        {
                          staticClass: "edit",
                          on: {
                            click: function($event) {
                              return _vm.editAddress(
                                items.id,
                                items.name,
                                items.phone,
                                items.address,
                                items.preAddr,
                                items.code
                              )
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-edit-outline" }),
                          _vm._v("编辑 ")
                        ]
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.deleteAddress(items.id)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-delete-solid" }),
                          _vm._v("删除 ")
                        ]
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "btnBox", on: { click: _vm.addressEdit } }, [
          _vm._v("新增地址")
        ]),
        _c(
          "van-dialog",
          {
            attrs: { "show-cancel-button": "" },
            on: { confirm: _vm.postModel, cancel: _vm.closeModel },
            model: {
              value: _vm.deleteBox,
              callback: function($$v) {
                _vm.deleteBox = $$v
              },
              expression: "deleteBox"
            }
          },
          [
            _c("div", { staticClass: "centerbox" }, [
              _vm._v("确定要删除该地址吗？")
            ])
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }